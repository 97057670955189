import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { THEME, useTheme } from 'state/storage'
import styles from './homeCard.module.scss'

export const HomeCard = ({ href, imageSrc, title, description, logo, id }) => {
    const theme = useTheme()
    return (
        <Link
            href={href}
            className={classNames(
                ` w-full xl:w-[350px] 2xl:w-[380px] 
                    h-[500px] sm:h-[248px] xl:h-[500px] 
                    flex
                    flex-col sm:flex-row-reverse xl:flex-col
                    justify-end
                    items-center sm:items-end xl:items-center
                    rounded-2xl overflow-hidden
                    transition-shadow duration-300 ease-in-out
                `,
                {
                    'shadow-customLight': theme === THEME.LIGHT,
                    'shadow-customDark': theme === THEME.DARK,
                    'hover:shadow-hoverCardLight': theme === THEME.LIGHT,
                    'hover:bg-hoverCardDark': theme === THEME.DARK,
                    'bg-cardDark': theme === THEME.DARK,
                    'bg-white': theme === THEME.LIGHT,
                }
            )}
        >
            <div className={'w-full h-full overflow-hidden'}>
                <Image
                    width='0'
                    height='0'
                    sizes='100vw'
                    src={imageSrc}
                    alt={title}
                    className={styles[id]}
                />
            </div>
            <div className='px-10 py-[32px] pb-[42px] sm:pr-0 lg:pr-10 min-[640px]:w-[300px] lg:w-auto sm:basis-4/5 xl:basis-auto'>
                <div className='flex items-center h-[32px]'>
                    <h4>{title}</h4>
                    <div className='ml-2 flex items-center'>{logo}</div>
                </div>
                <p className='text-gray-600 mt-2'>{description}</p>
            </div>
        </Link>
    )
}
