import classNames from 'classnames'
import { Link } from 'components/ui/links/link'
import { DIRECTION_HORIZONTAL, ScrollbarWithGradient } from 'components/ui/scrollbarWithGradient'
import { FloatingIcons } from 'components/vsp/vsp'
import { fetchCmsContent, vspQuery } from 'data/cmsQueries'
import { useEffectOnceOnMount } from 'hooks'
import Image from 'next/image'
import { useState } from 'react'
import { Feature } from './feature'

import { Button } from 'components/ui/buttons/button'
import styles from './vspSection.module.scss'

export const VspSection = () => {
    const [cmsContent, setCmsContent] = useState('')

    useEffectOnceOnMount(() => {
        async function fetchAndSetCmsContent() {
            const htmlContent = await fetchCmsContent(vspQuery)
            setCmsContent(htmlContent?.pageBy?.vsp ?? '')
        }
        fetchAndSetCmsContent()
    })

    const icons = cmsContent?.vsps ?? []
    const midPoint = Math.ceil((icons?.length ?? 0) / 2)
    const firstSlice = icons?.slice(0, midPoint)
    const secondSlice = icons?.slice(midPoint)

    const viewAllLink = cmsContent?.globalLinks?.viewAllUrl

    const trustedBy = cmsContent?.trustedBy ?? []

    return (
        <div className={styles.vspSection}>
            <ScrollbarWithGradient direction={DIRECTION_HORIZONTAL} maxMediaBreakPoint={'350px'}>
                <div className={classNames(styles.featureWrap, '!mb-[40px]')}>
                    <Feature
                        icon={'/static/svg/vsp-tag-extended.svg'}
                        label='Meet the Staking Rewards Verified Providers'
                        description='Stake with More Confidence and Save Time in Due-Diligence by Delegating to Verified Provider'
                    />
                </div>
            </ScrollbarWithGradient>
            <div className={styles.iconsWrap}>
                {firstSlice && <FloatingIcons icons={firstSlice} withGradient={false} />}
                {secondSlice && <FloatingIcons icons={secondSlice} withGradient={true} />}
            </div>
            <div className={styles.buttons}>
                {viewAllLink && (
                    <div className={styles.btnWrap}>
                        <Link href={viewAllLink} blank>
                            <Button iconClass='icon-arrow-right'>View All</Button>
                        </Link>
                    </div>
                )}
                <div className={styles.btnWrap}>
                    <Link href={'/vsp'}>
                        <Button variant='black' iconClass='icon-arrow-right'>
                            Learn More
                        </Button>
                    </Link>
                </div>
            </div>
            <ScrollbarWithGradient direction={DIRECTION_HORIZONTAL} maxMediaBreakPoint={'1000px'}>
                <div className={styles.features}>
                    {[
                        {
                            label: 'Verified Best Practice',
                            description: 'The Industry’s Standard for Best Practice Staking Providers',
                        },
                        {
                            label: 'Save Time',
                            description: 'Simplify and Streamline Your Due-Diligence before Delegating',
                        },
                        {
                            label: 'Minimize Risks',
                            description: 'Delegate Confidently to Reliable and Trustworthy Staking Providers',
                        },
                    ].map((feature, idx) => (
                        <div key={`feature-vsp-${idx}`} className={styles.featureWrap}>
                            <div className='flex flex-col text-center'>
                                <p className='mb-[8px] text-t2-600'>{feature.label}</p>
                                <p className='text-description'>{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </ScrollbarWithGradient>
            {trustedBy && (
                <div className={styles.trustedByWrap}>
                    <h2 className='mb-[32px]'>Trusted by Industry Leaders</h2>
                    <div className={styles.trustedBy}>
                        {trustedBy?.map((el, i) => (
                            <div className={styles.logo} key={`leader-${i}`}>
                                <Image
                                    src={el?.sourceUrl}
                                    alt={el?.title}
                                    className={styles.img}
                                    fill
                                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
