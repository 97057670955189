import { ApiBanner } from 'components/ads/apiBanner'
import { Hero } from 'components/home/hero'
import dynamic from 'next/dynamic'

import { VspSection } from './vspSection'

import classNames from 'classnames'
import { AdBanner } from 'components/ads/adBanner'
import { NewsletterSection } from 'components/ads/newsletterSection'
import { ElementId, SlotId } from 'components/ads/utils'
import { ListAssetsHomePage } from 'components/assets/archive/assetsTable'
import { useParamsFromUrl } from 'components/assets/archive/hooks'
import { START_TIME_SECONDS, VIDEO_ID } from 'components/calculator/constants'
import { YoutubeLink } from 'components/ui/links/youtubeLink'
import { TableArchiveContextProvider } from 'contexts/tableArchiveContext'
import styles from './home.module.scss'

// Dynamically scales overlapping photos depending on the browser's viewport width
// Import dynamically to prevent hydration
const SummitBanner = dynamic(() => import('components/ads/summitBanner').then(module => module.SummitBanner), {
    ssr: false,
})

const Calculator = dynamic(() => import('components/calculator').then(module => module.Calculator), {
    ssr: false,
})

export function HomePage() {
    const [params, setParams] = useParamsFromUrl()

    return (
        <>
            <TableArchiveContextProvider params={params} minRowWidthPx={1024}>
                <div className='bg'>
                    <div className={styles.backgroundGradient1}></div>
                    <div className={styles.backgroundGradient2}></div>
                    <div className={styles.backgroundGradient3}></div>
                </div>

                <div className={classNames(styles.home, 'dark:bg-black')}>
                    <div className={`container`}>
                        <Hero />
                        <div className={styles.tableWrap}>
                            <div className={styles.topWrap}>
                                <div className={styles.headerWrap}>
                                    <h3>Explore Top Staking Assets</h3>
                                    <p className='text-description'>
                                        Compare Their Performance with Staking Analytics - Then Investigate Deeper.
                                    </p>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <ListAssetsHomePage />
                            </div>
                        </div>
                        {/* <CtaHomePage className='mb-12' /> */}
                        <div className='mb-14'>
                            <div className={styles.topWrap}>
                                <div className={styles.headerWrap}>
                                    <h3>Calculate Your Staking Rewards</h3>
                                    <p className='text-description'>
                                        Examine the long-term compounding effect of staking - per asset, provider,
                                        staking amount and price scenario.
                                    </p>
                                </div>
                                <div className={styles.linkWrap}>
                                    <YoutubeLink
                                        href={`https://youtube.com/watch?v=${VIDEO_ID}&t=${START_TIME_SECONDS}s`}
                                        label={'Learn how to use the calculator'}
                                    />
                                </div>
                            </div>
                            <Calculator noRedirect />
                        </div>
                        <div className={styles.adWrap}>
                            <AdBanner slotId={SlotId.FooterBanner} id={ElementId.FooterBanner} />
                        </div>
                        <div className={styles.vspWrap}>
                            <VspSection />
                        </div>
                        <div className={styles.banners}>
                            <ApiBanner />
                            <SummitBanner />
                            <NewsletterSection />
                        </div>
                    </div>
                </div>
            </TableArchiveContextProvider>
        </>
    )
}
