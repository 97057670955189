import classNames from 'classnames'
import { SubscribeForm } from 'components/forms/subscribeForm'
import { ArticlePost } from 'components/journal/articlePost'
import { useJournalPosts } from 'components/journal/hooks'
import { Skeleton } from 'components/ui/skeleton'
import { useDeferredRendering } from 'hooks/useDeferredRendering'
import { useRef } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { MailerLiteGroupId } from 'utils/constants'

import { GTMEvent } from 'utils/GTM'
import styles from './newsletterSection.module.scss'

const POSTS_COUNT = 4

export const NewsletterSection = () => {
    const showVerticalPosts = useMediaPredicate('(max-width: 1199px)')

    const ref = useRef(null)
    const isReady = useDeferredRendering(ref)
    const { data: posts, isLoadingPosts } = useJournalPosts('Staking Insider', 0, POSTS_COUNT, '', isReady)
    const isLoading = !isReady || isLoadingPosts

    return (
        <div ref={ref} className={styles.newsletterSection} data-cy='cy-newsletter-section'>
            <div className={classNames('container', styles.hfull)}>
                <div className={styles.content}>
                    <div className={styles.headerWrap}>
                        <h3>Staking Insider Newsletter</h3>
                        <p className='text-description'>
                            Join 75k+ subscribers reading the latest staking data & insights weekly.
                        </p>
                    </div>
                    <div className={styles.latestPosts}>
                        {isLoading
                            ? Array.from({ length: POSTS_COUNT }, (_, index) => index).map(idx => (
                                  <div key={`latest-post-${idx}`} className={styles.skeleton}>
                                      <Skeleton width={'100%'} height={'100%'} />
                                  </div>
                              ))
                            : posts
                                  ?.slice(0, POSTS_COUNT)
                                  ?.map((post, idx) => (
                                      <ArticlePost
                                          key={`post-${post?.slug}-${idx}`}
                                          post={post?.attributes}
                                          category={''}
                                          isDynamic={showVerticalPosts}
                                          isLoading={isLoading}
                                      />
                                  ))}
                    </div>
                    <SubscribeForm
                        className={styles.subscribe}
                        groupId={MailerLiteGroupId.StakingInsider}
                        event={GTMEvent.StakingInsiderSubscribed}
                        eventFrom='inPage'
                    />
                </div>
            </div>
        </div>
    )
}
