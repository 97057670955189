import classNames from 'classnames'
import { Button, Link } from 'components/ui'
import Image from 'next/image'
import { THEME, useTheme } from 'state'

import styles from './apiBanner.module.scss'

export const ApiBanner = () => {
    const theme = useTheme()
    return (
        <div className={styles.apiBanner} data-theme={theme} data-cy='cy-api-banner'>
            <div className={classNames('container', styles.hfull)}>
                <div className={styles.content}>
                    <div className={styles.screenshot}>
                        <Image
                            className={styles.image}
                            src={`/static/img/${theme === THEME.DARK ? 'dark' : 'light'}-code.png`}
                            alt='Query'
                            width={640}
                            height={422}
                        />
                    </div>
                    <div className={styles.info}>
                        <h3 className='mb-[16px]'>Staking Data API</h3>
                        <p className={classNames('text-description', styles.description)}>
                            Access 1250+ unique data points across 167 assets and 100k+ validators.
                        </p>
                        <div className={styles.grid}>
                            <div className={classNames(styles.header, styles.header1)}>
                                <p>Up to 3 years</p>
                                <p>Historical Data</p>
                            </div>
                            <div className={classNames(styles.header, styles.header2)}>
                                <p>Trusted by Industry Leaders</p>
                                <div className={styles.icons}>
                                    <span className={classNames(`icon icon-bloomberg`, styles.bloomberg)} />
                                    <span className={classNames(`icon icon-coinbase`, styles.coinbase)} />
                                    <span className={classNames(`icon icon-kraken-full`, styles.kraken)} />
                                </div>
                            </div>
                            <Link className={styles.btn1} href={'https://api-docs.stakingrewards.com/'} blank={false}>
                                <Button variant='black' iconClass='icon-arrow-right' className='w-full'>
                                    API Docs
                                </Button>
                            </Link>
                            <Link className={styles.btn2} href={'/staking-data-api'} blank={false}>
                                <Button variant='white' iconClass='icon-arrow-right' className='w-full'>
                                    Get Started For Free
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
